import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./Signup.css"; // Ensure this import is present
import getApiUrl from "../utils.js";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const apiUrl = getApiUrl();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      return window.alert("Passwords don't match");
    }

    const res = await fetch(`${apiUrl}/signup`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
        confirmPassword,
      }),
    });
    const data = await res.json();
    if (!data) {
      window.alert("Fill in all details");
    } else if (res.status === 422) {
      window.alert("User already exists");
    } else {
      window.alert("Registration Successful");
      navigate("/login");
    }
  };

  return (
    <>
      <video className="video-background" autoPlay muted loop>
        <source src="https://videos.pexels.com/video-files/7680115/7680115-sd_960_506_25fps.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="signup-container">
        <img src={require('./logo.jpeg')} alt="Logo" className="logo-image" />
        <h1 className="mint-management">Mint Management</h1>
        <h1>Sign Up</h1>
        <form method="POST">
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Confirm Password:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" onClick={handleSubmit}>
            Sign Up
          </button>
        </form>
        <p>
          Already have an account? <Link to="/login">Sign In</Link>
        </p>
      </div>
    </>
  );
};

export default Signup;