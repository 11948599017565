import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Alert.css";
import getApiUrl from "../utils.js";

const Alert = () => {
  const [products, setProducts] = useState([]);
  const apiUrl = getApiUrl();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const res = await fetch(`${apiUrl}/get-products`);
        const data = await res.json();
        setProducts(data);
      } catch (err) {
        console.log("Error in data fetching", err);
      }
    };

    fetchProduct();
  }, [apiUrl]);

  const filteredProducts = products.filter((product) => product.stock < 5);

  const handleGoBack = () => {
    localStorage.setItem('alerted', false);  // Reset the alert flag for future sessions if needed
    navigate("/dashboard");
  };

  return (
    <div className="alert-container">
      <div className="alert-container-box">
        <h2>Stock Alert!</h2>
        <p>Products going Out Of Stock</p>
        <table>
          <thead>
            <tr>
              <th>Serial No.</th>
              <th>Product Name</th>
              <th>Current Stock (Cartons)</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.length === 0 ? (
              <tr>
                <td colSpan="3">No stock is going out of stock</td>
              </tr>
            ) : (
              filteredProducts.map((product, index) => (
                <tr key={product.productId}>
                  <td>{index + 1}</td>
                  <td>{product.productId}</td>
                  <td>{product.stock}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <button onClick={handleGoBack}>Go to Dashboard</button>
      </div>
    </div>
  );
};

export default Alert;
