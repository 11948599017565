import React, { useState, useEffect } from "react";
import axios from "axios";
import getApiUrl from "../utils.js";
import { useNavigate } from "react-router-dom";

const ProductList = () => {
  const [classes, setClasses] = useState([]);
  const [productClass, setProductClass] = useState("");
  const [products, setProducts] = useState([]);
  const [addedStock, setAddedStock] = useState({});
  const [error, setError] = useState("");
  const apiUrl = getApiUrl();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductClasses = async () => {
      try {
        const res = await fetch(`${apiUrl}/show-class`);
        const data = await res.json();
        setClasses(data);
        console.log("Product Classes Fetched:", data);
      } catch (err) {
        console.error("Error fetching product classes:", err);
      }
    };

    fetchProductClasses();
  }, [apiUrl]);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/products-by-class/${productClass}`
      );
      setProducts(response.data);
      console.log("setProducts", response.data);
      setError("");

      // Initialize addedStock with empty values for each product
      const initialStock = response.data.reduce((acc, product) => {
        acc[product.productId] = "";
        return acc;
      }, {});
      setAddedStock(initialStock);
      // console.log("Products Fetched:", response.data);
    } catch (err) {
      setProducts([]);
      setError(`No products found for class "${productClass}"`);
      console.error("Error fetching products:", err);
    }
  };

  const handleProductClassChange = (e) => {
    setProductClass(e.target.value);
  };

  const handleStockChange = (productId, value) => {
    setAddedStock((prevStock) => ({
      ...prevStock,
      [productId]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchProducts();
  };

  const handleAddBulkProduct = async () => {
    try {
      const response = await axios.post(`${apiUrl}/bulk-add-products`, {
        productClass,
        updatedStock: addedStock,
      });
      console.log("Stock updated successfully:", response.data);
      fetchProducts();
    } catch (error) {
      console.error("Error updating stock:", error);
    }
  };

  return (
    <div>
      <h1>Product Inventory</h1>
      <div className="revoke-order-buttons">
        <button onClick={() => navigate("/dashboard")}>
          Go Back to Dashboard
        </button>
        <button onClick={() => navigate("/view-inventory")}>
          View Inventory
        </button>
        <button onClick={() => navigate("/add-product")}>Add Products</button>
        <button onClick={() => navigate("/update-stock")}>
          Punch In Order
        </button>
      </div>
      <br />
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="productClass">Select Product Class:</label>
          <select
            value={productClass}
            onChange={handleProductClassChange}
            required
          >
            <option value="">Select a Class</option>
            {classes.map((cls) => (
              <option key={cls.id} value={cls.productClass}>
                {cls.productClass}
              </option>
            ))}
          </select>
        </div>
        <br />
        <button type="submit">Fetch Products</button>
      </form>

      {error && <p style={{ color: "red" }}>{error}</p>}

      {products.length > 0 && (
        <div>
          <table>
            <thead>
              <tr>
                <th>Product ID</th>
                <th>Current Stock</th>
                <th>Added Stock</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.productId}>
                  <td>{product.productId}</td>
                  <td>{product.stock}</td>
                  <td>
                    <input
                      type="number"
                      value={addedStock[product.productId] || ""}
                      onChange={(e) =>
                        handleStockChange(product.productId, e.target.value)
                      }
                    />
                  </td>
                  <td>${product.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <button type="button" onClick={handleAddBulkProduct}>
            Add Products
          </button>
          {/* <button type="button">Add Products</button> */}
        </div>
      )}
    </div>
  );
};

export default ProductList;
