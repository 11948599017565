import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./UpdateStock.css";
import getApiUrl from "../utils.js";

const UpdateStock = () => {
  const [getProduct, setGetProduct] = useState([]);
  const [selectedProductClass, setSelectedProductClass] = useState("");
  const [selectedProductId, setSelectedProductId] = useState("");
  const [sales, setSales] = useState("");
  const [currentStock, setCurrentStock] = useState("");
  const [employees, setEmployees] = useState([]);
  const [classes, setClasses] = useState([]);
  const [customers, setCustomers] = useState([]);
  const apiUrl = getApiUrl();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productRes = await fetch(`${apiUrl}/get-products`);
        const productData = await productRes.json();
        setGetProduct(productData);

        const classRes = await fetch(`${apiUrl}/show-class`);
        const classData = await classRes.json();
        setClasses(classData);

        // Fetch customer data for displaying cards
        const customerRes = await fetch(
          "https://6acca635c9ef42ab8227fafa42684570.api.mockbin.io/"
        );
        const customerData = await customerRes.json();

        // Correctly set customer data from the response object
        setCustomers(customerData.customers);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };
    fetchData();
  }, [apiUrl]);

  const handleCardClick = (customer) => {
    navigate("/punch-order", {
      state: { customerName: customer.customerName },
    });
  };

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const res = await fetch(`${apiUrl}/show-class`);
        const data = await res.json();
        setClasses(data);
        console.log("setClasses", data);
      } catch (err) {
        console.error("Error fetching products:", err);
      }
    };
    fetchClasses();
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const res = await fetch(`${apiUrl}/get-products`);
        const data = await res.json();
        setGetProduct(data);
        console.log("setGetProduct", data);
      } catch (err) {
        console.error("Error fetching products:", err);
      }
    };
    fetchProduct();
  }, []);

  return (
    <div className="update-stock-container">
      <h2>Update Stock</h2>

      {/* Render customer data as cards */}
      <div className="customer-cards">
        {customers.map((customer) => (
          <div
            key={customer.customerId}
            className="customer-card"
            onClick={() => handleCardClick(customer)}
          >
            <h3>{customer.customerName}</h3>
          </div>
        ))}
      </div>

      <div className="navigation-buttons">
        <button onClick={() => navigate("/dashboard")} className="nav-button">
          Dashboard
        </button>

        <button
          onClick={() => navigate("/view-inventory")}
          className="nav-button"
        >
          View Inventory
        </button>
        <button
          onClick={() => navigate("/revoke-order")}
          className="nav-button"
        >
          Revoke Order
        </button>
      </div>
    </div>
  );
};

export default UpdateStock;
