// src/Context/InventoryContext.js
import React, { createContext, useReducer, useContext } from "react";

const InventoryStateContext = createContext();
const InventoryDispatchContext = createContext();

const initialState = {
  products: [],
  history: [],
  defectiveItems: [],
};

const inventoryReducer = (state, action) => {
  console.log("Reducer action:", action); // Log action
  console.log("Current state:", state); // Log current state

  switch (action.type) {
    case "ADD_PRODUCT":
      return {
        ...state,
        products: [...state.products, action.payload],
        history: [
          ...state.history,
          {
            type: "add",
            ...action.payload,
            stockBefore: 0,
            stockAfter: action.payload.stock,
            date: new Date().toISOString(),
            revoked: false,
            employeeId: action.payload.employeeId,
          },
        ],
      };

    case "ADD_DEFECTIVE_PRODUCT":
      const productIndex = state.products.findIndex(
        (product) => product.productName === action.payload.productName
      );

      if (productIndex === -1) {
        console.error("Product not found:", action.payload.productName); // Log error
        return state;
      }

      const productToUpdate = state.products[productIndex];
      const stockBefore = productToUpdate.stock;
      const newStock = stockBefore - action.payload.quantity; // Subtract defective quantity

      const updatedProducts = state.products.map((product, index) =>
        index === productIndex ? { ...product, stock: newStock } : product
      );

      return {
        ...state,
        products: updatedProducts,
        defectiveItems: [
          ...state.defectiveItems,
          {
            productName: action.payload.productName,
            quantity: action.payload.quantity,
            date: new Date().toISOString(),
          },
        ],
        history: [
          ...state.history,
          {
            type: "defective",
            productName: action.payload.productName,
            stockBefore: stockBefore,
            stockAfter: newStock,
            date: new Date().toISOString(),
            revoked: false,
            employeeId: action.payload.employeeId, // Ensure employeeId is provided if needed
          },
        ],
      };

    case "UPDATE_STOCK":
      const productToUpdateForStock = state.products.find(
        (product) => product.id === action.payload.id
      );

      if (!productToUpdateForStock) {
        console.error("Product not found:", action.payload.id); // Log error
        return state;
      }

      // Find defective items for the product
      const defectiveItem = state.defectiveItems.find(
        (item) => item.productName === productToUpdateForStock.productName
      );

      // Calculate the new stock after accounting for defective items
      const newStockForUpdate = defectiveItem
        ? action.payload.stock - defectiveItem.quantity
        : action.payload.stock;

      // Update the products list with the new stock value
      const updatedProductsForStock = state.products.map((product) =>
        product.id === action.payload.id
          ? { ...product, stock: newStockForUpdate }
          : product
      );

      return {
        ...state,
        products: updatedProductsForStock,
        history: [
          ...state.history,
          {
            type: "update",
            id: action.payload.id,
            productName: productToUpdateForStock.productName,
            stockBefore: productToUpdateForStock.stock,
            stockAfter: newStockForUpdate,
            date: new Date().toISOString(),
            revoked: false,
            employeeId: action.payload.employeeId,
          },
        ],
      };

    case "REVOKE_ORDER":
      const revokedEntry = state.history.find(
        (entry) =>
          entry.id === action.payload.id && entry.date === action.payload.date
      );

      if (!revokedEntry) {
        console.error("Revoked entry not found:", action.payload); // Log error
        return state;
      }

      const updatedProductsAfterRevoke = state.products.map((product) =>
        product.id === action.payload.id
          ? {
              ...product,
              stock:
                product.stock +
                (revokedEntry.stockBefore - revokedEntry.stockAfter),
            }
          : product
      );

      return {
        ...state,
        history: state.history.map((entry) =>
          entry.id === action.payload.id && entry.date === action.payload.date
            ? { ...entry, revoked: true }
            : entry
        ),
        products: updatedProductsAfterRevoke,
      };

    case "REVOKE_PARTIAL_ORDER":
      const partialRevokeEntry = state.history.find(
        (entry) =>
          entry.id === action.payload.id && entry.date === action.payload.date
      );

      if (!partialRevokeEntry) {
        console.error("Partial revoke entry not found:", action.payload); // Log error
        return state;
      }

      return {
        ...state,
        history: state.history.map((entry) =>
          entry.id === action.payload.id && entry.date === action.payload.date
            ? { ...entry, revoked: true }
            : entry
        ),
        products: state.products.map((product) =>
          product.productName === partialRevokeEntry.productName
            ? {
                ...product,
                stock: action.payload.newStockAfterRevoke,
              }
            : product
        ),
      };

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const InventoryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(inventoryReducer, initialState);

  return (
    <InventoryStateContext.Provider value={state}>
      <InventoryDispatchContext.Provider value={dispatch}>
        {children}
      </InventoryDispatchContext.Provider>
    </InventoryStateContext.Provider>
  );
};

export const useInventoryState = () => useContext(InventoryStateContext);
export const useInventoryDispatch = () => useContext(InventoryDispatchContext);
