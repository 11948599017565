import React, { useEffect, useState } from "react";
import "./PunchOrder.css";
import getApiUrl from "../utils.js";
import { useNavigate, useLocation } from "react-router-dom";

const PunchOrder = () => {
  const [productClasses, setProductClasses] = useState([]);
  const [products, setProducts] = useState([]);
  const [rows, setRows] = useState([
    {
      productClass: "",
      productId: "",
      stock: "",
      sellInCtn: "",
      price: "",
      employeeId: "",
      date: "",
      keyPair: "",
    },
  ]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [customers, setCustomers] = useState([]);
  const apiUrl = getApiUrl();

  const navigate = useNavigate();
  const location = useLocation();

  const customerName = location.state?.customerName || "";

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        // Fetch product classes
        const classRes = await fetch(`${apiUrl}/show-class`);
        const classData = await classRes.json();
        setProductClasses(classData);

        // Fetch products
        const productRes = await fetch(`${apiUrl}/get-products`);
        const productData = await productRes.json();
        setProducts(productData);

        const customerRes = await fetch(`${apiUrl}/get-customer`);
        const customerData = await customerRes.json();
        setCustomers(customerData);
        // Prefill selected customer if customerName is passed
        if (customerName) {
          setSelectedCustomer(customerName);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };
    fetchProductData();
  }, [apiUrl, customerName]);

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        productClass: "",
        productId: "",
        stock: "",
        sellInCtn: "",
        price: "",
        employeeId: "",
        date: new Date(),
        keyPair: "",
      },
    ]);
  };

  const handleRemoveRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleInputChange = (index, field, value) => {
    const newRows = [...rows];

    if (field === "productId") {
      const selectedProduct = products.find(
        (product) => product.productId === value
      );

      if (selectedProduct) {
        newRows[index].stock = selectedProduct.stock;
        newRows[index].keyPair = selectedProduct.keyPair;
        newRows[index].date = new Date().toISOString().slice(0, 10);
      }
    }

    newRows[index][field] = value;
    setRows(newRows);
  };

  const handleSubmitOrder = async () => {
    try {
      const payload = {
        customerName: selectedCustomer,
        orders: rows.map((row) => ({
          productClass: row.productClass,
          productId: row.productId,
          stock: row.stock,
          sellInCtn: row.sellInCtn,
          price: row.price,
          employeeId: row.employeeId,
          date: row.date,
          keyPair: row.keyPair,
        })),
      };
      const response = await fetch(`${apiUrl}/orders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        alert("Order placed successfully");
        navigate("/view-inventory");
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.message}`);
      }
    } catch (error) {
      console.error("Error placing order:", error);
      alert("Failed to place the order");
    }
  };

  const handleSelectedCustomer = (e) => {
    setSelectedCustomer(e.target.value);
  };

  return (
    <div className="punch-order-container">
      <h2 className="punch-order-container-h2">Punch Order</h2>

      <div className="customer-name-container">
        <h3>Select Customer</h3>
        <select
          value={selectedCustomer}
          className="filter-select"
          onChange={handleSelectedCustomer}
        >
          <option value="">Select a customer</option>
          {customers.map((customer) => (
            <option key={customer.customerId} value={customer.customerName}>
              {customer.customerName}
            </option>
          ))}
        </select>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th>Product Class</th>
              <th>Product ID</th>
              <th>Stock(CTN)</th>
              <th>Stock to Sell(CTN)</th>
              <th>Price per Pair (INR)</th>
              <th>EmployeeID</th>
              <th>Date</th>
              <th>Key Pair</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td>
                  <select
                    value={row.productClass}
                    onChange={(e) =>
                      handleInputChange(index, "productClass", e.target.value)
                    }
                    className="filter-select"
                  >
                    <option value="">Select Class</option>
                    {productClasses.map((cls) => (
                      <option key={cls.id} value={cls.productClass}>
                        {cls.productClass}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    value={row.productId}
                    onChange={(e) =>
                      handleInputChange(index, "productId", e.target.value)
                    }
                    className="filter-select"
                  >
                    <option value="">Select ID</option>
                    {products
                      .filter(
                        (product) => product.productClass === row.productClass
                      )
                      .map((product) => (
                        <option
                          key={product.productId}
                          value={product.productId}
                        >
                          {product.productId}
                        </option>
                      ))}
                  </select>
                </td>
                <td>
                  <input
                    type="number"
                    value={row.stock}
                    onChange={(e) =>
                      handleInputChange(index, "stock", e.target.value)
                    }
                    className="input-field"
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={row.sellInCtn}
                    onChange={(e) =>
                      handleInputChange(index, "sellInCtn", e.target.value)
                    }
                    className="input-field"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={row.price}
                    onChange={(e) =>
                      handleInputChange(index, "price", e.target.value)
                    }
                    className="input-field"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={row.employeeId}
                    onChange={(e) =>
                      handleInputChange(index, "employeeId", e.target.value)
                    }
                    className="input-field"
                  />
                </td>
                <td>
                  <input
                    type="date"
                    value={row.date || new Date()}
                    onChange={(e) =>
                      handleInputChange(index, "date", e.target.value)
                    }
                    className="input-field"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={row.keyPair}
                    onChange={(e) =>
                      handleInputChange(index, "keyPair", e.target.value)
                    }
                    className="input-field"
                    readOnly
                  />
                </td>
                <td>
                  <button
                    onClick={() => handleRemoveRow(index)}
                    className="remove-product-button"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="button-punch-order">
          <button onClick={handleAddRow} className="add-product-button">
            Add Product
          </button>

          <button className="punch-order-button" onClick={handleSubmitOrder}>
            Punch Order
          </button>
          <button
            className="back-to-dashboard"
            onClick={() => navigate("/dashboard")}
          >
            Back To Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default PunchOrder;
