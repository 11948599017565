import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const checkAuthentication = () => {
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");

    if (!isLoggedIn) {
      console.log("User not authenticated, redirecting to login");
      navigate("/login"); // Redirect to login if not authenticated
    } else {
      setIsAuthenticated(true); // Allow access if authenticated
    }
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  if (!isAuthenticated) {
    return <div>Loading...</div>; // Show loading or blank until authentication is verified
  }

  return children; // Render protected component if authenticated
};

export default PrivateRoute;
