// import React, { useState, useEffect } from "react";
// import { useInventoryDispatch } from "../Context/InventoryContext";
// import { useNavigate } from "react-router-dom";
// import "./AddProduct.css";
// import getApiUrl from "../utils.js";

// const AddProduct = () => {
//   const dispatch = useInventoryDispatch();
//   const [productID, setProductID] = useState("");
//   const [stock, setStock] = useState("");
//   const [keyPair, setKeyPair] = useState("");
//   const [selectClass, setSelectClass] = useState("");
//   const [productClass, setProductClass] = useState([]);
//   const [newClass, setNewClass] = useState("");
//   const [isAddingNewClass, setIsAddingNewClass] = useState(false);
//   const [loading, setLoading] = useState(false); // Loading state
//   const [errorMessage, setErrorMessage] = useState(""); // Error message state

//   const navigate = useNavigate();
//   const apiUrl = getApiUrl();

//   // Fetch product classes when the component loads
//   useEffect(() => {
//     const fetchProductClasses = async () => {
//       setLoading(true);
//       try {
//         const res = await fetch(`${apiUrl}/show-class`);
//         if (!res.ok) throw new Error("Failed to fetch product classes");
//         const data = await res.json();
//         setProductClass(data);
//       } catch (err) {
//         console.error("Error fetching product classes:", err);
//         setErrorMessage(
//           "Error fetching product classes. Please try again later."
//         );
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchProductClasses();
//   }, [apiUrl]);

//   const handleAddProduct = async (e) => {
//     e.preventDefault();
//     const selectedClass = isAddingNewClass ? newClass : selectClass;

//     const newProduct = {
//       productClass: selectedClass,
//       productId: productID,
//       stock: parseInt(stock || 0, 10),
//       keyPair: keyPair,
//       before_stocks: 0,
//     };

//     try {
//       setLoading(true);
//       const res = await fetch(`${apiUrl}/add-products`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(newProduct),
//       });

//       const data = await res.json();
//       if (res.status === 200 && !data.err) {
//         dispatch({
//           type: "ADD_PRODUCT",
//           payload: newProduct,
//         });
//         resetForm();
//         window.alert("Product added successfully");
//         window.location.reload();
//       } else if (res.status === 202) {
//         resetForm();
//         window.alert("Added more stocks");
//       } else {
//         window.alert("Failed to add product");
//       }
//     } catch (error) {
//       console.error("Error adding product:", error);
//       window.alert(
//         "An error occurred while adding the product. Please try again."
//       );
//     } finally {
//       setLoading(false);
//     }
//   };

//   const resetForm = () => {
//     setSelectClass("");
//     setProductID("");
//     setKeyPair("");
//     setStock("");

//     setNewClass("");
//     setIsAddingNewClass(false);
//   };

//   return (
//     <div className="add-product-container">
//       <h2>Add Product</h2>
//       <form onSubmit={handleAddProduct} method="POST">
//         <div className="form-group">
//           <label>
//             Do you want to add a new class or continue with an existing class?
//           </label>
//           <div className="radio-group">
//             <div className="radio-item">
//               <input
//                 type="radio"
//                 name="classOption"
//                 value="existing"
//                 checked={!isAddingNewClass}
//                 onChange={() => setIsAddingNewClass(false)}
//               />
//               <label>Continue with Existing Class</label>
//             </div>
//             <div className="radio-item">
//               <input
//                 type="radio"
//                 name="classOption"
//                 value="new"
//                 checked={isAddingNewClass}
//                 onChange={() => setIsAddingNewClass(true)}
//               />
//               <label>Add a New Class</label>
//             </div>
//           </div>
//         </div>

//         {!isAddingNewClass && (
//           <div className="form-group">
//             <label>Product Class</label>
//             <select
//               className="styled-select"
//               value={selectClass}
//               onChange={(e) => setSelectClass(e.target.value)}
//               required
//             >
//               <option value="">Select Class</option>
//               {productClass.map((product) => (
//                 <option key={product.id} value={product.productClass}>
//                   {product.productClass}
//                 </option>
//               ))}
//             </select>
//           </div>
//         )}

//         {isAddingNewClass && (
//           <div className="form-group">
//             <label>New Product Class</label>
//             <input
//               type="text"
//               value={newClass}
//               onChange={(e) => setNewClass(e.target.value)}
//               required
//             />
//           </div>
//         )}

//         <div className="form-group">
//           <label>Product ID:</label>
//           <input
//             type="text"
//             value={productID}
//             onChange={(e) => setProductID(e.target.value)}
//             required
//           />
//         </div>

//         <div className="form-group">
//           <label>Stock (in Cartons, CTN):</label>
//           <input
//             type="number"
//             value={stock}
//             onChange={(e) => setStock(e.target.value)}
//             required
//           />
//         </div>

//         <div className="form-group">
//           <label>Key Pair (if applicable):</label>
//           <input
//             type="number"
//             value={keyPair}
//             onChange={(e) => setKeyPair(e.target.value)}
//             required
//           />
//         </div>

//         <button type="submit">Add Product</button>
//       </form>

//       <div className="navigation-buttons">
//         <button onClick={() => navigate("/dashboard")} className="nav-button">
//           Dashboard
//         </button>
//         <button onClick={() => navigate("/punch-order")} className="nav-button">
//           Punch In Order
//         </button>
//         <button
//           onClick={() => navigate("/view-inventory")}
//           className="nav-button"
//         >
//           View Inventory
//         </button>
//         <button
//           onClick={() => navigate("/revoke-order")}
//           className="nav-button"
//         >
//           Revoke Order
//         </button>
//       </div>
//     </div>
//   );
// };

// export default AddProduct;

import React, { useState, useEffect } from "react";
import { useInventoryDispatch } from "../Context/InventoryContext";
import { useNavigate } from "react-router-dom";
import "./AddProduct.css";
import getApiUrl from "../utils.js";

const AddProduct = () => {
  const dispatch = useInventoryDispatch();
  const [productID, setProductID] = useState("");
  const [stock, setStock] = useState("");
  const [keyPair, setKeyPair] = useState("");
  // const [Class, setClass] = useState("");
  const [productClass, setProductClass] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [errorMessage, setErrorMessage] = useState(""); // Error message state

  const navigate = useNavigate();
  const apiUrl = getApiUrl();

  // Fetch product classes when the component loads
  // useEffect(() => {
  //   const fetchProductClasses = async () => {
  //     setLoading(true);
  //     try {
  //       const res = await fetch(`${apiUrl}/show-class`);
  //       if (!res.ok) throw new Error("Failed to fetch product classes");
  //       const data = await res.json();
  //       setProductClass(data);
  //     } catch (err) {
  //       console.error("Error fetching product classes:", err);
  //       setErrorMessage(
  //         "Error fetching product classes. Please try again later."
  //       );
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchProductClasses();
  // }, [apiUrl]);

  const handleAddProduct = async (e) => {
    e.preventDefault();

    const newProduct = {
      productClass: productClass,
      productId: productID,
      stock: parseInt(stock || 0, 10),
      keyPair: keyPair,
      before_stocks: 0,
    };

    try {
      setLoading(true);
      const res = await fetch(`${apiUrl}/add-products`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newProduct),
      });

      const data = await res.json();
      if (res.status === 200 && !data.err) {
        dispatch({
          type: "ADD_PRODUCT",
          payload: newProduct,
        });
        resetForm();
        window.alert("Product added successfully");
      } else if (res.status === 202) {
        resetForm();
        window.alert("Added more stocks");
      } else {
        window.alert("Failed to add product");
      }
    } catch (error) {
      console.error("Error adding product:", error);
      window.alert(
        "An error occurred while adding the product. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setProductClass("");
    setProductID("");
    setKeyPair("");
    setStock("");
  };

  return (
    <div className="add-product-container">
      <h2>Add Product</h2>
      <form onSubmit={handleAddProduct} method="POST">
        {/* Product Class */}
        <div className="form-group">
          <label>Product Class</label>
          <input
            type="text"
            value={productClass}
            onChange={(e) => setProductClass(e.target.value)}
            required
          />
          {/* <select
            className="styled-select"
            value={productClass}
            onChange={(e) => setProductClass(e.target.value)}
            required
          >
            <option value="">Select Class</option>
            {productClass.map((product) => (
              <option key={product.id} value={product.productClass}>
                {product.productClass}
              </option>
            ))}
          </select> */}
        </div>

        {/* Product ID */}
        <div className="form-group">
          <label>Product ID:</label>
          <input
            type="text"
            value={productID}
            onChange={(e) => setProductID(e.target.value)}
            required
          />
        </div>

        {/* Stock */}
        <div className="form-group">
          <label>Stock (in Cartons, CTN):</label>
          <input
            type="number"
            value={stock}
            onChange={(e) => setStock(e.target.value)}
            required
          />
        </div>

        {/* Key Pair */}
        <div className="form-group">
          <label>Key Pair (if applicable):</label>
          <input
            type="number"
            value={keyPair}
            onChange={(e) => setKeyPair(e.target.value)}
            required
          />
        </div>

        {/* Submit Button */}
        <button type="submit">Add Product</button>
      </form>

      <div className="navigation-buttons">
        <button onClick={() => navigate("/dashboard")} className="nav-button">
          Dashboard
        </button>
        <button onClick={() => navigate("/punch-order")} className="nav-button">
          Punch In Order
        </button>
        <button
          onClick={() => navigate("/view-inventory")}
          className="nav-button"
        >
          View Inventory
        </button>
        <button
          onClick={() => navigate("/revoke-order")}
          className="nav-button"
        >
          Revoke Order
        </button>
      </div>
    </div>
  );
};

export default AddProduct;
