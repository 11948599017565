// import React, { useEffect, useState } from "react";
// import {
//   useInventoryState,
//   useInventoryDispatch,
// } from "../Context/InventoryContext";
// import { useNavigate } from "react-router-dom";
// import "./DefectiveProducts.css";
// import getApiUrl from "../utils.js";

// const DefectiveProducts = () => {
//   const dispatch = useInventoryDispatch();
//   const [product, setProduct] = useState([]);
//   const [selectedProduct, setSelectedProduct] = useState("");
//   const [defectiveQuantity, setDefectiveQuantity] = useState("");
//   const [defectiveProduct, setDefectiveProduct] = useState([]);
//   const apiUrl = getApiUrl();

//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchProduct = async () => {
//       try {
//         const res = await fetch(`${apiUrl}/get-products`);
//         const data = await res.json();
//         setProduct(data);
//       } catch (err) {
//         console.log("Error while fetching your product", err);
//       }
//     };
//     fetchProduct();
//   }, []);

//   const handleProductChange = (e) => {
//     setSelectedProduct(e.target.value);
//   };

//   const handleQuantityChange = (e) => {
//     setDefectiveQuantity(e.target.value);
//   };

//   const handleSubmit = async () => {
//     const newData = {
//       productId: selectedProduct,
//       quantity: parseInt(defectiveQuantity, 10),
//     };
//     console.log("selectedProduct=>", selectedProduct);
//     try {
//       const res = await fetch(`${apiUrl}/defective-product`, {
//         method: "POST",

//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(newData),
//       });

//       const data = await res.json();

//       console.log("data=>", data);

//       if (res.ok && !data.error) {
//         if (selectedProduct && defectiveQuantity) {
//           // dispatch({
//           //   type: "ADD_DEFECTIVE_PRODUCT",
//           //   payload: {
//           //     productCat: selectedProduct,
//           //     quantity: parseInt(defectiveQuantity, 10),
//           //     employeeId: "currentEmployeeId",
//           //   },
//           // });
//           setSelectedProduct("");
//           setDefectiveQuantity("");
//           navigate("/view-inventory");
//         }
//       } else if (res.status == 404) {
//         setSelectedProduct("");
//         setDefectiveQuantity("");
//         return window.alert("Product's stock is empty");
//       }
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   useEffect(() => {
//     const fetchDefectiveProduct = async () => {
//       try {
//         const res = await fetch(`${apiUrl}/get-defective`);
//         const data = await res.json();
//         setDefectiveProduct(data);
//         console.log("setDefectiveProduct", data);
//       } catch (err) {
//         console.log("Error while fetching your defective product", err);
//       }
//     };
//     fetchDefectiveProduct();
//   }, []);

//   const formatDate = (dateString) => {
//     const options = {
//       year: "numeric",
//       month: "long",
//       day: "numeric",
//       hour: "numeric",
//       minute: "numeric",
//       second: "numeric",
//     };
//     return new Date(dateString).toLocaleString(undefined, options);
//   };

//   return (
//     <div className="defective-products-wrapper">
//       <nav className="navigation">
//         <button onClick={() => navigate("/dashboard")}>Dashboard</button>
//         <button onClick={() => navigate("/view-inventory")}>
//           View Inventory
//         </button>
//         <button onClick={() => navigate("/punch-order")}>Punch In Order</button>
//         <button onClick={() => navigate("/revoke-order")}>Revoke Order</button>
//         <button onClick={() => navigate("/defective-products")}>
//           Defective Products
//         </button>
//       </nav>
//       <h2>Defective Products</h2>
//       <div className="form-container">
//         <select
//           value={selectedProduct}
//           onChange={handleProductChange}
//           className="filter-select"
//         >
//           <option value="">Select Product</option>
//           {product.map((product) => (
//             <option key={product.productId} value={product.productId}>
//               {product.productId}
//             </option>
//           ))}
//         </select>
//         <input
//           type="number"
//           placeholder="Defective Quantity"
//           value={defectiveQuantity}
//           onChange={handleQuantityChange}
//           className="filter-input"
//         />
//         <button onClick={handleSubmit} className="submit-button">
//           Submit
//         </button>
//       </div>
//       {/* show defective product quantity  */}
//       <div>
//         <table>
//           <thead>
//             <tr>
//               <th>Product ID</th>
//               <th>Defective Quantity</th>
//               <th>Date</th>
//             </tr>
//           </thead>
//           <tbody>
//             {defectiveProduct.length === 0 ? (
//               <tr>
//                 <td colSpan="3">No Defective Product</td>
//               </tr>
//             ) : (
//               defectiveProduct.map((item, index) => (
//                 <tr key={index}>
//                   <td>{item.productId}</td>
//                   <td>{item.defective}</td>
//                   <td>{formatDate(item.date)}</td>
//                 </tr>
//               ))
//             )}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default DefectiveProducts;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./DefectiveProducts.css";
import getApiUrl from "../utils.js";

const DefectiveProducts = () => {
  const [selectedClass, setSelectedClass] = useState("");
  const [product, setProduct] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [defectiveQuantity, setDefectiveQuantity] = useState("");
  const [defectiveProduct, setDefectiveProduct] = useState([]);
  const apiUrl = getApiUrl();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const res = await fetch(`${apiUrl}/get-products`);
        const data = await res.json();
        setProduct(data);
      } catch (err) {
        console.log("Error while fetching your product", err);
      }
    };
    fetchProduct();
  }, []);

  // Handle product class change and filter product IDs
  const handleProductClassChange = (e) => {
    const selectedClass = e.target.value;
    setSelectedClass(selectedClass);

    // Filter products based on the selected class
    const filtered = product.filter((p) => p.productClass === selectedClass);
    setFilteredProducts(filtered);
  };

  const handleProductChange = (e) => {
    setSelectedProduct(e.target.value);
  };

  const handleQuantityChange = (e) => {
    setDefectiveQuantity(e.target.value);
  };

  const handleSubmit = async () => {
    const newData = {
      productId: selectedProduct,
      quantity: parseInt(defectiveQuantity, 10),
    };

    try {
      const res = await fetch(`${apiUrl}/defective-product`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData),
      });

      const data = await res.json();
      if (res.ok && !data.error) {
        if (selectedProduct && defectiveQuantity) {
          setSelectedProduct("");
          setDefectiveQuantity("");
          navigate("/view-inventory");
        }
      } else if (res.status === 404) {
        setSelectedProduct("");
        setDefectiveQuantity("");
        return window.alert("Product's stock is empty");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchDefectiveProduct = async () => {
      try {
        const res = await fetch(`${apiUrl}/get-defective`);
        const data = await res.json();
        setDefectiveProduct(data);
      } catch (err) {
        console.log("Error while fetching your defective product", err);
      }
    };
    fetchDefectiveProduct();
  }, []);

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  return (
    <div className="defective-products-wrapper">
      <nav className="navigation">
        <button onClick={() => navigate("/dashboard")}>Dashboard</button>
        <button onClick={() => navigate("/view-inventory")}>
          View Inventory
        </button>
        <button onClick={() => navigate("/punch-order")}>Punch In Order</button>
        <button onClick={() => navigate("/revoke-order")}>Revoke Order</button>
        <button onClick={() => navigate("/defective-products")}>
          Defective Products
        </button>
      </nav>
      <h2>Defective Products</h2>
      <div className="form-container">
        {/* Product Class Dropdown */}
        <select
          value={selectedClass}
          onChange={handleProductClassChange}
          className="filter-select"
        >
          <option value="">Select Product Class</option>
          {Array.from(new Set(product.map((p) => p.productClass))).map(
            (classItem) => (
              <option key={classItem} value={classItem}>
                {classItem}
              </option>
            )
          )}
        </select>

        {/* Product ID Dropdown (filtered based on selected class) */}
        <select
          value={selectedProduct}
          onChange={handleProductChange}
          className="filter-select"
        >
          <option value="">Select Product</option>
          {filteredProducts.map((p) => (
            <option key={p.productId} value={p.productId}>
              {p.productId}
            </option>
          ))}
        </select>

        <input
          type="number"
          placeholder="Defective Quantity"
          value={defectiveQuantity}
          onChange={handleQuantityChange}
          className="filter-input"
        />
        <button onClick={handleSubmit} className="submit-button">
          Submit
        </button>
      </div>
      {/* show defective product quantity  */}
      <div>
        <table>
          <thead>
            <tr>
              <th>Product ID</th>
              <th>Defective Quantity</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {defectiveProduct.length === 0 ? (
              <tr>
                <td colSpan="3">No Defective Product</td>
              </tr>
            ) : (
              defectiveProduct.map((item, index) => (
                <tr key={index}>
                  <td>{item.productId}</td>
                  <td>{item.defective}</td>
                  <td>{formatDate(item.date)}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DefectiveProducts;
