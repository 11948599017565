import React from "react";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";

const Dashboard = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem("isLoggedIn"); // Remove the session info
    navigate("/login"); // Redirect to login page after logout
  };
  

  return (
    <div className="dashboard-wrapper">
      {/* Top-right button for Login/Signup */}
      {/* <div className="top-right">
        <button className="login-signup-btn" onClick={() => navigate("/login")}>
          Logout
        </button>
      </div> */}
       <div className="top-right">
        <button className="login-signup-btn" onClick={handleLogout}>
          Logout
        </button>
      </div>
      <div className="main-content">
        <div className="header">
          <h1>Welcome to Your Dashboard</h1>
          <p>Select an option below to get started.</p>
        </div>
        <div className="cards-container">
          <div className="card" onClick={() => navigate("/add-product")}>
            <h3>Add Product</h3>
            <p>Add new products to the inventory.</p>
          </div>
          <div className="card" onClick={() => navigate("/view-inventory")}>
            <h3>View Inventory</h3>
            <p>View all products.</p>
          </div>
          <div className="card" onClick={() => navigate("/punch-order")}>
            <h3>Punch Order</h3>
            <p>Record a new order</p>
          </div>
          <div className="card" onClick={() => navigate("/revoke-order")}>
            <h3>Revoke Order</h3>
            <p>Cancel an existing order</p>
          </div>
          {/* <div className="card" onClick={() => navigate("/defective-products")}>
            <h3>Defective Products</h3>
            <p>Report a defective products.</p>
          </div> */}
          <div className="card" onClick={() => navigate("/sales-analytics")}>
            <h3>Sales Analytics</h3>
            <p>View and analyze sales data.</p>
          </div>
          {/* 
          <div className="card" onClick={() => navigate("/add-bulk-products")}>
            <h3>Add Bulk Products</h3>
            <p>Add multiple products to the inventory at once.</p>
          </div> */}
          <div className="card" onClick={() => navigate("/add-customer")}>
            <h3>Add Customer</h3>
            <p>Add a new customer to the system</p>
          </div>
          <div className="card" onClick={() => navigate("/generate-bill")}>
            <h3>Generate Bill</h3>
            <p>Generate bill to the System</p>
          </div>
          <div className="card" onClick={() => navigate("/order-summary")}>
            <h3>Order Summary</h3>
            <p>Generate Summary to the System</p>
          </div>
          <div className="card" onClick={() => navigate("/sales-prediction")}>
            <h3>Sales Prediction</h3>
            <p>predict the future sales</p>
          </div>
          <div className="card" onClick={() => navigate("/price-prediction")}>
            <h3>Price Prediction</h3>
            <p>predict the priceing of products</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;


