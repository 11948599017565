import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ViewInventory.css";
import getApiUrl from "../utils.js";
import jsPDF from "jspdf";
import "jspdf-autotable";

const ViewInventory = () => {
  const [product, setProduct] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);
  const [productClassData, setProductClassData] = useState([]);
  const [selectedProductClass, setSelectedProductClass] = useState(null);
  const [searchQueryProductClass, setSearchQueryProductClass] = useState("");
  const [searchQueryInventory, setSearchQueryInventory] = useState("");
  const [searchQueryHistoryName, setSearchQueryHistoryName] = useState("");
  const [searchQueryHistoryDate, setSearchQueryHistoryDate] = useState("");
  const [showHistoryTable, setShowHistoryTable] = useState(false);
  const [showClassTable, setShowClassTable] = useState(false);
  const navigate = useNavigate();
  const apiUrl = getApiUrl();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const res = await fetch(`${apiUrl}/get-products`);
        const data = await res.json();
        setProduct(data);
        groupByProductClass(data);
      } catch (err) {
        console.log("Error in data fetching", err);
      }
    };

    const fetchOrderHistory = async () => {
      try {
        const res = await fetch(`${apiUrl}/get-history`);
        const getData = await res.json();
        setOrderHistory(getData);
      } catch (err) {
        console.log(err);
      }
    };

    fetchProduct();
    fetchOrderHistory();
  }, []);

  const groupByProductClass = (data) => {
    const grouped = data.reduce((acc, item) => {
      if (acc[item.productClass]) {
        acc[item.productClass].stock += item.stock;
      } else {
        acc[item.productClass] = {
          productClass: item.productClass,
          stock: item.stock,
        };
      }
      return acc;
    }, {});

    setProductClassData(Object.values(grouped));
  };

  // Search for order history by customer name or date
  const filteredHistory = orderHistory.filter((entry) => {
    const entryDate = new Date(entry.date);
    const formattedEntryDate = `${entryDate.getFullYear()}-${String(
      entryDate.getMonth() + 1
    ).padStart(2, "0")}-${String(entryDate.getDate()).padStart(2, "0")}`;

    return (
      entry.customerName
        .toLowerCase()
        .includes(searchQueryHistoryName.toLowerCase()) &&
      (formattedEntryDate.includes(searchQueryHistoryDate) ||
        searchQueryHistoryDate === "")
    );
  });

  // Filter product classes based on the search query
  const filteredProductClassData = productClassData.filter((item) =>
    item.productClass
      .toLowerCase()
      .includes(searchQueryProductClass.toLowerCase())
  );

  // Filter products based on selected product class and search query
  const filteredProducts = product.filter(
    (item) => item.productClass === selectedProductClass
  );

  // Handle search for product inventory
  const handleSearchInventory = (e) => {
    setSearchQueryInventory(e.target.value);
  };

  // Handle search for order history by customer name
  const handleSearchHistoryName = (e) => {
    setSearchQueryHistoryName(e.target.value);
  };

  // Handle search for order history by date
  const handleSearchHistoryDate = (e) => {
    setSearchQueryHistoryDate(e.target.value);
  };

  // Filtered products based on search query
  const filteredProductsInventory = filteredProducts.filter(
    (item) =>
      item.productId
        .toLowerCase()
        .includes(searchQueryInventory.toLowerCase()) ||
      item.productClass
        .toLowerCase()
        .includes(searchQueryInventory.toLowerCase())
  );

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  const handleClassClick = (productClass) => {
    setSelectedProductClass(productClass);
  };

  const exportClassPDF = () => {
    if (!product) {
      console.error("Product data is missing, cannot export to PDF.");
      alert("No product data to export.");
      return;
    }

    const groupedProducts = product.reduce((acc, p) => {
      if (!acc[p.productClass]) {
        acc[p.productClass] = {
          productClass: p.productClass,
          totalStock: p.stock,
        };
      } else {
        acc[p.productClass].totalStock += p.stock;
      }
      return acc;
    }, {});

    const productDataArray = Object.values(groupedProducts);

    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text("Product Class Details", 14, 22);

    const tableColumn = ["Product Class", "Total CTN", "Date"];
    const tableRows = [];

    productDataArray.forEach((p) => {
      const productData = [
        p.productClass,
        p.totalStock,
        new Date().toLocaleString(),
      ];
      tableRows.push(productData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30,
    });

    doc.save(`product_class_Info.pdf`);
  };

  const exportProductPDF = async (selectedProductClass) => {
    if (!product || product.length === 0) {
      console.error("Product data is missing, cannot export to PDF.");
      alert("No product data to export.");
      return;
    }

    const filteredProducts = product.filter(
      (p) => p.productClass === selectedProductClass
    );

    if (filteredProducts.length === 0) {
      alert(`No products found for class: ${selectedProductClass}`);
      return;
    }

    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text(`Product Details for Class: ${selectedProductClass}`, 14, 22);

    const tableColumn = [
      "Product Id",
      "Product Class",
      "Stock CTN",
      "Key Pair",
    ];

    const tableRows = [];

    filteredProducts.forEach((p) => {
      const productData = [p.productId, p.productClass, p.stock, p.keyPair];
      tableRows.push(productData);
    });

    await doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30,
    });

    doc.save(`${selectedProductClass}_Info.pdf`);
  };

  return (
    <div className="view-inventory-container">
      <h1>View Inventory</h1>
      <div className="navigation-buttons">
        <button onClick={() => navigate("/dashboard")}>Dashboard</button>
        <button onClick={() => navigate("/add-product")}>Add Products</button>
        <button onClick={() => navigate("/punch-order")}>Punch In Order</button>
        <button onClick={() => navigate("/revoke-order")}>Revoke Order</button>
      </div>

      <h2>Product Class Inventory</h2>

      <button
        className="toggle-button"
        onClick={() => setShowClassTable((prev) => !prev)}
      >
        {showClassTable ? "Hide Class Table" : "Show Class Table"}
      </button>

      {showClassTable && (
        <>
          <div className="search-export-wrapper">
            <input
              type="text"
              placeholder="Search by Product Class"
              value={searchQueryProductClass}
              onChange={(e) => setSearchQueryProductClass(e.target.value)}
              className="search-input"
            />
            <button className="export-btn-class" onClick={exportClassPDF}>
              Export pdf
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th>Product Class</th>
                <th>Total CTN</th>
              </tr>
            </thead>
            <tbody>
              {productClassData.length === 0 ? (
                <tr>
                  <td colSpan="2">No product class found</td>
                </tr>
              ) : (
                filteredProductClassData.map((obj, index) => (
                  <tr
                    key={index}
                    onClick={() => handleClassClick(obj.productClass)}
                    className="selected-class"
                  >
                    <td>{obj.productClass}</td>
                    <td>{obj.stock}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </>
      )}

      {selectedProductClass && showClassTable ? (
        <>
          <h2>Product Inventory for Class: {selectedProductClass}</h2>
          <div className="search-export-wrapper">
            <input
              type="text"
              placeholder="Search by Product ID or Class"
              value={searchQueryInventory}
              onChange={handleSearchInventory}
              className="search-input"
            />
            <button
              className="export-btn"
              onClick={() => exportProductPDF(selectedProductClass)}
            >
              Export pdf
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th>Product ID</th>
                <th>Product Class</th>
                <th>Stock CTN</th>
                <th>Key Pair</th>
              </tr>
            </thead>
            <tbody>
              {filteredProductsInventory.length === 0 ? (
                <tr>
                  <td colSpan="4">No product found</td>
                </tr>
              ) : (
                filteredProductsInventory.map((obj, index) => (
                  <tr key={index}>
                    <td>{obj.productId}</td>
                    <td>{obj.productClass}</td>
                    <td>{obj.stock}</td>
                    <td>{obj.keyPair}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </>
      ) : (
        ""
      )}

      <h2>Order History</h2>

      <button
        className="toggle-button"
        onClick={() => setShowHistoryTable((prev) => !prev)}
      >
        {showHistoryTable ? "Hide History Table" : "Show History Table"}
      </button>

      {showHistoryTable && (
        <div className="search-date-wrapper">
          <input
            type="text"
            placeholder="Search by Customer Name"
            value={searchQueryHistoryName}
            onChange={handleSearchHistoryName}
            className="search-input"
          />
          <input
            type="date"
            value={searchQueryHistoryDate}
            onChange={handleSearchHistoryDate}
            className="date"
          />
        </div>
      )}

      {showHistoryTable && (
        <table>
          <thead>
            <tr>
              <th>Order ID</th>
              <th>Product ID</th>
              <th>Customer Name</th>
              <th>Stock to sell (CTN)</th>
              <th>Price</th>
              <th>Employee ID</th>
              <th>Date & Time</th>
              <th>Revoked</th>
            </tr>
          </thead>
          <tbody>
            {filteredHistory.length === 0 ? (
              <tr>
                <td colSpan="8">No order history found</td>
              </tr>
            ) : (
              filteredHistory.map((entry, index) => (
                <tr key={index}>
                  <td>{entry.orderID}</td>
                  <td>{entry.productId}</td>
                  <td>{entry.customerName}</td>
                  <td>{entry.sellInCtn}</td>
                  <td>{entry.price}</td>
                  <td>{entry.employee_id}</td>
                  <td>{formatDate(entry.date)}</td>
                  <td>{entry.revoked ? "Yes" : "No"}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ViewInventory;
