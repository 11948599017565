import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./PricePrediction.css";
import getApiUrl from "../utils";

const PricePrediction = () => {
  const [reviews, setReviews] = useState("");
  const [week, setWeek] = useState("");
  const [sellerRating, setSellerRating] = useState("");
  const [cost, setCost] = useState("");
  const [brand, setBrand] = useState("");
  const [rating, setRating] = useState("");
  const [month, setMonth] = useState("");
  const [category, setCategory] = useState("");
  const [quantity, setQuantity] = useState("");
  const [predictedPrice, setPredictedPrice] = useState(null);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const apiUrl = getApiUrl();

  const handlePredictPrice = async () => {
    try {
      const response = await fetch(`${apiUrl}/price-prediction`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Reviews: reviews,
          Week: week,
          "Seller Rating": sellerRating,
          "Shipping Cost": cost,
          Brand: brand,
          Rating: rating,
          Month: month,
          Category: category,
          "Quantity/Size": quantity,
        }),
      });

      if (!response.ok) {
        throw new Error("Error fetching the prediction.");
      }

      const data = await response.json();
      setPredictedPrice(data.prediction);
      setError(null);
    } catch (err) {
      setError("Failed to get price prediction. Please try again.");
      setPredictedPrice(null);
    }
  };

  return (
    <div className="price-prediction-wrapper">
      <nav className="navigation">
        <button onClick={() => navigate("/dashboard")}>Dashboard</button>
        <button onClick={() => navigate("/view-inventory")}>
          View Inventory
        </button>
        <button onClick={() => navigate("/sales-prediction")}>
          Sales Prediction
        </button>
        <button onClick={() => navigate("/revoke-order")}>Revoke Order</button>
        <button onClick={() => navigate("/order-summary")}>
          Order Summary
        </button>
      </nav>
      <h2>Input Data for Price Prediction</h2>
      <div className="price-prediction-input">
        <label>Reviews</label>
        <input type="text" value={reviews} onChange={(e) => setReviews(e.target.value)} />

        <label>Week</label>
        <input type="text" value={week} onChange={(e) => setWeek(e.target.value)} />

        <label>Seller Rating</label>
        <input type="text" value={sellerRating} onChange={(e) => setSellerRating(e.target.value)} />

        <label>Shipping Cost</label>
        <input type="text" value={cost} onChange={(e) => setCost(e.target.value)} />

        <label>Brand</label>
        <input value={brand} onChange={(e) => setBrand(e.target.value)} />

        <label>Rating</label>
        <input type="text" value={rating} onChange={(e) => setRating(e.target.value)} />

        <label>Month</label>
        <input value={month} onChange={(e) => setMonth(e.target.value)} />

        <label>Category</label>
        <input value={category} onChange={(e) => setCategory(e.target.value)} />

        <label>Quantity (Size)</label>
        <input type="text" value={quantity} onChange={(e) => setQuantity(e.target.value)} />

        <button onClick={handlePredictPrice}>Predict</button>
        
       
        {predictedPrice && (
          <div className="prediction-result">
            <h3>Predicted Price: {predictedPrice}</h3>
          </div>
        )}

        {error && (
          <div className="error-message">
            <p>{error}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PricePrediction;
