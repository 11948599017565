// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import "./Login.css";
// import getApiUrl from "../utils.js";

// const Login = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [error, setError] = useState("");
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();
//   const apiUrl = getApiUrl();

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!email || !password) {
//       setError("Please fill in all fields.");
//       return;
//     }

//     setLoading(true);
//     setError("");

//     try {
//       const res = await fetch(`${apiUrl}/login`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ email, password }),
//       });
//       const data = await res.json();

//       if (res.status === 200 && !data.err) {
//         window.alert("Logged in successfully");
//         // Save token or authentication info if needed
//         localStorage.setItem("authToken", data.token); // Assuming your API returns a token
//         navigate("/alert"); // Navigate to the dashboard
//       } else {
//         setError("Invalid credentials. Please try again.");
//       }
//     } catch (error) {
//       setError("An error occurred. Please try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <>
//       <video className="video-background" autoPlay muted loop>
//         <source
//           src="https://videos.pexels.com/video-files/5699536/5699536-sd_640_360_24fps.mp4"
//           type="video/mp4"
//         />
//         Your browser does not support the video tag.
//       </video>
//       <div className="login-container">
//         <img src={require("./logo.jpeg")} alt="Logo" className="logo-image" />
//         <h1 className="mint-management">Mint Management</h1>
//         <h2>Login</h2>
//         <form onSubmit={handleSubmit} method="POST">
//           <div className="form-group">
//             <label>Email:</label>
//             <input
//               type="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//             />
//           </div>
//           <div className="form-group">
//             <label>Password:</label>
//             <input
//               type="password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               required
//             />
//           </div>
//           {error && <p className="error">{error}</p>}
//           <button type="submit" disabled={loading}>
//             {loading ? "Logging in..." : "Login"}
//           </button>
//         </form>
//         <p>
//           Don't have an account? <Link to="/signup">Sign Up</Link>
//         </p>
//       </div>
//     </>
//   );
// };

// export default Login;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import getApiUrl from "../utils.js";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const apiUrl = getApiUrl();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("Please fill in all fields.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const res = await fetch(`${apiUrl}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      const data = await res.json();

      if (res.status === 200 && !data.err) {
        window.alert("Logged in successfully");
        // Save the session info after login
        sessionStorage.setItem("isLoggedIn", "true");
        navigate("/alert"); // Navigate to the dashboard or protected route
      } else {
        setError("Invalid credentials. Please try again.");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <video className="video-background" autoPlay muted loop>
        <source
          src="https://videos.pexels.com/video-files/5699536/5699536-sd_640_360_24fps.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <div className="login-container">
        <img src={require("./logo.jpeg")} alt="Logo" className="logo-image" />
        <h1 className="mint-management">Mint Management</h1>
        <h2>Login</h2>
        <form onSubmit={handleSubmit} method="POST">
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="error">{error}</p>}
          <button type="submit" disabled={loading}>
            {loading ? "Logging in..." : "Login"}
          </button>
        </form>
        <p>
          Don't have an account? <Link to="/signup">Sign Up</Link>
        </p>
      </div>
    </>
  );
};

export default Login;
