import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SalesPrediction.css';

const SalesPrediction = () => {
  const [rating, setRating] = useState('');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [category, setCategory] = useState('');
  const [item, setItem] = useState('');
  const [items, setItems] = useState([]); 
  const [predictionResult, setPredictionResult] = useState(null); 
  const [error, setError] = useState(null); 

  const navigate = useNavigate(); 

  // Hardcoded categories and corresponding items
  const categories = ['Auto Parts', 'Electronics', 'Groceries'];
  const categoryItems = {
    'Auto Parts': ['Item 1 - Auto Parts', 'Item 2 - Auto Parts'],
    'Electronics': ['Item 1 - Electronics', 'Item 2 - Electronics'],
    'Groceries': ['Item 1 - Groceries', 'Item 2 - Groceries']
  };

  const handleCategoryChange = (selectedCategory) => {
    setCategory(selectedCategory);
    setItems(categoryItems[selectedCategory] || []); 
    setItem(''); 
  };

  const handlePrediction = async () => {
    if (!rating || !day || !month || !category || !item) {
      alert('Please fill out all fields!');
      return;
    }

    const formData = {
      category,
      item_name: item,
      rating,
      day,
      month,
    };

    try {
      const response = await fetch('/sales-prediction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setPredictionResult(data.predicted_quantity_sold);
        setError(null); // Reset any previous error
      } else {
        throw new Error('Prediction failed');
      }
    } catch (err) {
      console.error('Error predicting sales:', err);
      setError('Error during prediction');
    }
  };

  return (
    <>
      <div className='sales-prediction-wrapper'>
        <nav className="navigation">
          <button onClick={() => navigate("/dashboard")}>Dashboard</button>
          <button onClick={() => navigate("/view-inventory")}>View Inventory</button>
          <button onClick={() => navigate("/price-prediction")}>Price Prediction</button>
          <button onClick={() => navigate("/revoke-order")}>Revoke Order</button>
          <button onClick={() => navigate("/order-summary")}>Order Summary</button>
        </nav>

        <h2>Select a Category and Item</h2>

        <div className='sales-prediction-input'>
          <label>Rating</label>
          <select value={rating} onChange={(e) => setRating(e.target.value)}>
            <option value="">--Select Rating--</option>
            <option value="1">1 Star</option>
            <option value="2">2 Stars</option>
            <option value="3">3 Stars</option>
            <option value="4">4 Stars</option>
            <option value="5">5 Stars</option>
          </select>

          <label>Day</label>
          <select value={day} onChange={(e) => setDay(e.target.value)}>
            <option value="">--Select Day--</option>
            {[...Array(31)].map((_, i) => (
              <option key={i} value={i + 1}>{i + 1}</option>
            ))}
          </select>

          <label>Month</label>
          <input
            type="text"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            placeholder="Enter Month (e.g. 9 for August)"
          />

          <label>Category</label>
          <select value={category} onChange={(e) => handleCategoryChange(e.target.value)}>
            <option value="">--Select a Category--</option>
            {categories.map((cat, idx) => (
              <option key={idx} value={cat}>{cat}</option>
            ))}
          </select>

          <label>Item</label>
          <select value={item} onChange={(e) => setItem(e.target.value)} disabled={!category}>
            <option value="">--Select an Item--</option>
            {items.map((itm, idx) => (
              <option key={idx} value={itm}>{itm}</option>
            ))}
          </select>

          <button onClick={handlePrediction}>Predict</button>
        </div>

        {error && <div className="error-message">{error}</div>}
        {predictionResult && (
          <div className="prediction-result">
            <strong>Predicted Quantity Sold:</strong> {predictionResult}
          </div>
        )}
      </div>
    </>
  );
};

export default SalesPrediction;


