import React, { useEffect, useRef, useState } from "react";
import { Chart, registerables } from "chart.js";
import { useInventoryState } from "../Context/InventoryContext";
import { useNavigate } from "react-router-dom";
import "./SalesAnalytics.css";
import getApiUrl from "../utils.js";

Chart.register(...registerables);

const SalesAnalytics = () => {
  const { products, history } = useInventoryState();
  const [salesData, setSalesData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("daily");
  const [stockFilter, setStockFilter] = useState("");
  const [priceFilter, setPriceFilter] = useState("");
  const [chartType, setChartType] = useState("line");
  const [reportData, setReportData] = useState(null);
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const navigate = useNavigate();
  const [topEmployees, setTopEmployees] = useState(1);
  const [topProducts, setTopProducts] = useState(1);
  const [product, setProduct] = useState([]);
  const [orderHistory, setOrderHistory] = useState([]);

  const [employeeIdSearch, setEmployeeIdSearch] = useState(""); // New state for employee search
  const [employeeSales, setEmployeeSales] = useState(null); // New state to store employee sales result
  const apiUrl = getApiUrl();

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const res = await fetch(`${apiUrl}/get-products`);
        const data = await res.json();
        setProduct(data);
      } catch (err) {
        console.log("error", err);
      }
    };

    const fetchOrderHistory = async () => {
      try {
        const res = await fetch(`${apiUrl}/get-history`);
        const data = await res.json();
        setOrderHistory(data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchProduct();
    fetchOrderHistory();
  }, []);

  const calculateBestEmployees = (limit) => {
    const employeeSales = {};

    orderHistory.forEach((entry) => {
      if (!entry.revoked) {
        if (!employeeSales[entry.employee_id]) {
          employeeSales[entry.employee_id] = 0;
        }
        const sales = entry.before_stock - entry.after_stock; // Calculate sales as the difference between stock before and after
        employeeSales[entry.employee_id] += sales;
      }
    });

    const sortedEmployees = Object.entries(employeeSales)
      .sort((a, b) => b[1] - a[1])
      .slice(0, limit);

    return sortedEmployees;
  };

  const calculateBestProducts = (limit) => {
    const productSales = {};

    orderHistory.forEach((entry) => {
      if (!entry.revoked) {
        if (!productSales[entry.productId]) {
          productSales[entry.productId] = 0;
        }
        const sales = entry.before_stock - entry.after_stock; // Calculate sales as the difference between stock before and after
        productSales[entry.productId] += sales;
      }
    });

    const sortedProducts = Object.entries(productSales)
      .sort((a, b) => b[1] - a[1])
      .slice(0, limit);

    return sortedProducts;
  };

  useEffect(() => {
    const salesEntries = orderHistory.filter(
      (entry) => entry.type === "update" || !entry.revoked
    );

    const productSales = salesEntries.reduce((acc, entry) => {
      if (!acc[entry.productId]) {
        acc[entry.productId] = {
          totalSales: 0,
          details: [],
        };
      }
      const sales = entry.before_stock - entry.after_stock; // Calculate sales as the difference between stock before and after
      acc[entry.productId].totalSales += sales;
      acc[entry.productId].details.push({ ...entry, sales });
      return acc;
    }, {});

    const formattedData = Object.entries(productSales).map(
      ([productId, { totalSales, details }]) => ({
        productId,
        totalSales,
        details,
        ...product.find((product) => product.productId === productId),
      })
    );
    setSalesData(formattedData);
    setFilteredData(formattedData);
  }, [orderHistory, product]);

  useEffect(() => {
    if (!chartRef.current) return;

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    const ctx = chartRef.current.getContext("2d");
    const labels = selectedProduct
      ? filteredData[0].details.map((detail) =>
          new Date(detail.date).toLocaleString()
        )
      : filteredData.map((entry) => entry.productId);
    const data = selectedProduct
      ? filteredData[0].details.map((detail) =>
          Math.abs(detail.after_stock - detail.before_stock)
        )
      : filteredData.map((entry) => entry.totalSales);

    const colors = [
      "rgba(255, 99, 132, 0.2)",
      "rgba(54, 162, 235, 0.2)",
      "rgba(255, 206, 86, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(153, 102, 255, 0.2)",
      "rgba(255, 159, 64, 0.2)",
      "rgba(255, 99, 132, 0.4)",
      "rgba(54, 162, 235, 0.4)",
      "rgba(255, 206, 86, 0.4)",
      "rgba(75, 192, 192, 0.4)",
      "rgba(153, 102, 255, 0.4)",
      "rgba(255, 159, 64, 0.4)",
    ];

    const borderColors = colors.map((color) => color.replace("0.2", "1"));

    chartInstanceRef.current = new Chart(ctx, {
      type: chartType,
      data: {
        labels,
        datasets: [
          {
            label: "Number of Sales",
            data,
            backgroundColor: chartType === "pie" ? colors : colors[0],
            borderColor: chartType === "pie" ? borderColors : borderColors[0],
            borderWidth: 1,
            fill: selectedProduct && chartType !== "pie",
            barThickness: chartType === "bar" ? 20 : undefined,
            hoverOffset: chartType === "pie" ? 10 : undefined,
            borderRadius: chartType === "bar" ? 10 : undefined,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => {
                if (!selectedProduct) {
                  const productId = context.label;
                  const productData = filteredData.find(
                    (item) => item.productId === productId
                  );
                  if (productData) {
                    const details = productData.details
                      .map((detail) => {
                        const { date, before_stock, after_stock } = detail;
                        return `Date: ${new Date(
                          date
                        ).toLocaleString()}, Stock Before: ${before_stock}, Stock After: ${after_stock}`;
                      })
                      .join("\n");
                    return `Sales: ${context.raw}\n${details}`;
                  }
                  return `Sales: ${context.raw}`;
                }
                return `Sales: ${context.raw}`;
              },
            },
          },
          legend: {
            labels: {
              color: "#ffffff",
            },
          },
          datalabels: {
            color: "#ffffff",
            display: chartType !== "pie",
          },
        },
        scales:
          chartType !== "pie"
            ? {
                x: {
                  beginAtZero: true,
                  ticks: {
                    color: "#ffffff",
                  },
                },
                y: {
                  beginAtZero: true,
                  ticks: {
                    color: "#ffffff",
                  },
                  title: {
                    display: true,
                    text: "Number of Sales",
                    color: "#ffffff",
                  },
                },
              }
            : {},
      },
    });
  }, [filteredData, selectedProduct, chartType]);

  const bestEmployees = calculateBestEmployees(topEmployees);
  const bestProducts = calculateBestProducts(topProducts);

  const handleProductChange = (e) => {
    const productId = e.target.value;
    setSelectedProduct(productId);

    if (productId === "") {
      setFilteredData(salesData);
      setReportData(null);
    } else {
      const productData = salesData.filter(
        (entry) => entry.productId === productId
      );
      setFilteredData(productData);
    }
  };

  const handleTimePeriodChange = (e) => {
    const period = e.target.value;
    setSelectedTimePeriod(period);

    const filteredByTime = salesData.map((product) => {
      const filteredDetails = product.details.filter((detail) => {
        const now = new Date();
        const detailDate = new Date(detail.date);
        if (period === "daily") {
          return now.toDateString() === detailDate.toDateString();
        } else if (period === "monthly") {
          return (
            now.getMonth() === detailDate.getMonth() &&
            now.getFullYear() === detailDate.getFullYear()
          );
        } else if (period === "quarterly") {
          const currentQuarter = Math.floor((now.getMonth() + 3) / 3);
          const detailQuarter = Math.floor((detailDate.getMonth() + 3) / 3);
          return (
            currentQuarter === detailQuarter &&
            now.getFullYear() === detailDate.getFullYear()
          );
        }
        return true;
      });

      const totalSales = filteredDetails.reduce(
        (acc, detail) =>
          acc + Math.abs(detail.after_stock - detail.before_stock),
        0
      );

      return {
        ...product,
        totalSales,
        details: filteredDetails,
      };
    });

    setFilteredData(filteredByTime);
  };

  const handleStockFilterChange = (e) => {
    const value = e.target.value;
    setStockFilter(value);

    const filteredByStock = salesData.filter(
      (product) => product.stock >= value
    );
    setFilteredData(filteredByStock);
  };

  const handlePriceFilterChange = (e) => {
    const value = e.target.value;
    setPriceFilter(value);

    const filteredByPrice = salesData.filter(
      (product) => product.price <= value
    );
    setFilteredData(filteredByPrice);
  };

  const handleChartTypeChange = (e) => {
    setChartType(e.target.value);
  };

  const generateReport = () => {
    if (selectedProduct) {
      const productData = salesData.find(
        (entry) => entry.productId === selectedProduct
      );
      setReportData(productData);
    } else {
      setReportData(null);
    }
  };

  const handleEmployeeIdSearch = (e) => {
    e.preventDefault();
    const salesEntries = orderHistory.filter(
      (entry) => entry.employee_id === employeeIdSearch && !entry.revoked
    );
    const totalSales = salesEntries.reduce((acc, entry) => {
      const sales = entry.before_stock - entry.after_stock;
      return acc + sales;
    }, 0);
    setEmployeeSales({ id: employeeIdSearch, sales: totalSales });
  };

  return (
    <div className="sales-analytics-wrapper">
      <nav className="navigation">
        <button onClick={() => navigate("/dashboard")}>Dashboard</button>
        <button onClick={() => navigate("/view-inventory")}>
          View Inventory
        </button>
        <button onClick={() => navigate("/punch-order")}>Punch In Order</button>
        <button onClick={() => navigate("/revoke-order")}>Revoke Order</button>
      </nav>
      <h2>Sales Analytics</h2>

      <div className="filters-container">
        <select
          value={selectedProduct}
          onChange={handleProductChange}
          className="filter-select"
        >
          <option value="">All Products</option>
          {salesData.map((entry) => (
            <option key={entry.productId} value={entry.productId}>
              {entry.productId}
            </option>
          ))}
        </select>
        <select
          value={selectedTimePeriod}
          onChange={handleTimePeriodChange}
          className="filter-select"
        >
          <option value="daily">Daily</option>
          <option value="monthly">Monthly</option>
          <option value="quarterly">Quarterly</option>
        </select>
        <input
          type="number"
          placeholder="Stock Filter"
          value={stockFilter}
          onChange={handleStockFilterChange}
          className="filter-input"
        />
        <input
          type="number"
          placeholder="Price Filter"
          value={priceFilter}
          onChange={handlePriceFilterChange}
          className="filter-input"
        />
        <select
          value={chartType}
          onChange={handleChartTypeChange}
          className="filter-select"
        >
          <option value="line">Line Chart</option>
          <option value="bar">Bar Chart</option>
          <option value="pie">Pie Chart</option>
        </select>
        <button onClick={generateReport} className="report-button">
          Generate Report
        </button>
        <form
          onSubmit={handleEmployeeIdSearch}
          className="employee-search-form"
        >
          <label>Search Employee Sales by ID:</label>
          <input
            type="text"
            value={employeeIdSearch}
            onChange={(e) => setEmployeeIdSearch(e.target.value)}
            placeholder="Enter Employee ID"
          />
          <button type="submit">Search</button>
        </form>

        {employeeSales && (
          <div className="employee-sales-result">
            <h2>Sales for Employee ID: {employeeSales.id}</h2>
            <p>Total Sales: {employeeSales.sales}</p>
          </div>
        )}
        {reportData && (
          <div className="report">
            <h3>Report for {reportData.productCat}</h3>
            <p>Total Sales: {reportData.totalSales}</p>
            <ul>
              {reportData.details.map((detail) => (
                <li key={detail.date}>
                  Date: {new Date(detail.date).toLocaleString()}, Stock Before:{" "}
                  {detail.before_stock}, Stock After:{" "}
                  {detail.before_stock - detail.sales}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <canvas ref={chartRef} className="chart-canvas"></canvas>

      <div className="analytics-section">
        <h3>Best Employees</h3>
        <select
          value={topEmployees}
          onChange={(e) => setTopEmployees(parseInt(e.target.value))}
          className="filter-select"
        >
          <option value={1}>Best Employee</option>
          <option value={5}>Best 5 Employees</option>
          <option value={10}>Best 10 Employees</option>
        </select>
        <ul>
          {bestEmployees.map(([employeeId, sales], index) => (
            <li key={index}>
              {employeeId !== undefined ? employeeId : "Unknown Employee"}:{" "}
              {sales !== undefined ? sales : "No Sales"}
            </li>
          ))}
        </ul>
      </div>
      <div className="analytics-section">
        <h3>Best Products</h3>
        <select
          value={topProducts}
          onChange={(e) => setTopProducts(parseInt(e.target.value))}
          className="filter-select"
        >
          <option value={1}>Best Product</option>
          <option value={5}>Best 5 Products</option>
          <option value={10}>Best 10 Products</option>
        </select>
        <ul>
          {bestProducts.map(([productCat, sales], index) => (
            <li key={index}>
              {productCat}: {sales} sales
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SalesAnalytics;
