// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Dashboard from "./pages/Dashboard";
// import AddProduct from "./pages/AddProduct";
// import UpdateStock from "./pages/UpdateStock";
// import ViewInventory from "./pages/ViewInventory";
// import RevokeOrder from "./pages/RevokeOrder";
// import SalesAnalytics from "./pages/SalesAnalytics";
// import DefectiveProducts from "./pages/DefectiveProducts";
// import Login from "./pages/Login";
// import Signup from "./pages/Signup";
// import PunchOrder from "./pages/PunchOrder";
// import AddBulkProduct from "./pages/AddBulkProduct";
// import GenerateBill from "./pages/GenerateBill";
// import AddCustomer from "./pages/AddCustomer";
// import OrderSummary from "./pages/OrderSummary";
// import Alert from "./pages/Alert";
// import SalesPrediction from "./pages/SalesPrediction";
// import PricePrediction from "./pages/PricePrediction";
// import Home from './pages/Home';

// function App() {
//   return (<>
//     <Router>
//     {/* <Home/> */}
//       <div className="App">
//         <Routes>
//         <Route path="/" element={<Signup/>} />
//           <Route path="/dashboard" element={<Dashboard />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/signup" element={<Signup />} />
//           <Route path="/add-product" element={<AddProduct />} />
//           {/* <Route path="/update-stock" element={<UpdateStock />} /> */}
//           <Route path="/view-inventory" element={<ViewInventory />} />
//           <Route path="/revoke-order" element={<RevokeOrder />} />
//           <Route path="/sales-analytics" element={<SalesAnalytics />} />
//           <Route path="/defective-products" element={<DefectiveProducts />} />
//           <Route path="/punch-order" element={<PunchOrder />} />
//           <Route path="/add-bulk-products" element={<AddBulkProduct />} />
//           <Route path="/generate-bill" element={<GenerateBill />} />
//           <Route path="/add-customer" element={<AddCustomer />} />
//           <Route path="/order-summary" element={<OrderSummary/>}/>
//           <Route path="/alert" element={<Alert/>}/>
//           <Route path="/sales-prediction" element={<SalesPrediction/>}/>
//           <Route path="/price-prediction" element={<PricePrediction/>}/>
//         </Routes>
//       </div>
//     </Router>
//     </>
//   );
// }

// export default App;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import AddProduct from "./pages/AddProduct";
import UpdateStock from "./pages/UpdateStock";
import ViewInventory from "./pages/ViewInventory";
import RevokeOrder from "./pages/RevokeOrder";
import SalesAnalytics from "./pages/SalesAnalytics";
import DefectiveProducts from "./pages/DefectiveProducts";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import PunchOrder from "./pages/PunchOrder";
import AddBulkProduct from "./pages/AddBulkProduct";
import GenerateBill from "./pages/GenerateBill";
import AddCustomer from "./pages/AddCustomer";
import OrderSummary from "./pages/OrderSummary";
import Alert from "./pages/Alert";
import SalesPrediction from "./pages/SalesPrediction";
import PricePrediction from "./pages/PricePrediction";
import Home from './pages/Home'
import PrivateRoute from './Routes/PrivateRoute'; 

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          
          {/* Protected Routes */}
          <Route 
            path="/dashboard" 
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/add-product" 
            element={
              <PrivateRoute>
                <AddProduct />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/update-stock" 
            element={
              <PrivateRoute>
                <UpdateStock />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/view-inventory" 
            element={
              <PrivateRoute>
                <ViewInventory />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/revoke-order" 
            element={
              <PrivateRoute>
                <RevokeOrder />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/sales-analytics" 
            element={
              <PrivateRoute>
                <SalesAnalytics />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/defective-products" 
            element={
              <PrivateRoute>
                <DefectiveProducts />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/punch-order" 
            element={
              <PrivateRoute>
                <PunchOrder />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/add-bulk-products" 
            element={
              <PrivateRoute>
                <AddBulkProduct />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/generate-bill" 
            element={
              <PrivateRoute>
                <GenerateBill />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/add-customer" 
            element={
              <PrivateRoute>
                <AddCustomer />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/order-summary" 
            element={
              <PrivateRoute>
                <OrderSummary />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/alert" 
            element={
              <PrivateRoute>
                <Alert />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/sales-prediction" 
            element={
              <PrivateRoute>
                <SalesPrediction />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/price-prediction" 
            element={
              <PrivateRoute>
                <PricePrediction />
              </PrivateRoute>
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
