import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./OrderSummary.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import getApiUrl from "../utils";

const OrderSummary = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [bill, setBill] = useState(null);
  const [error, setError] = useState(null);
  const apiUrl = getApiUrl();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const customerRes = await fetch(`${apiUrl}/get-customer`);
        const customerData = await customerRes.json();
        setCustomers(customerData || []);
      } catch (err) {
        console.error("Error fetching customers:", err);
      }
    };
    fetchCustomers();
  }, [apiUrl]);

  const handleCustomerChange = (e) => {
    setSelectedCustomer(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSearch = async (event) => {
    event.preventDefault();

    if (!selectedCustomer || !startDate || !endDate) {
      alert("Please select a customer and date range!");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/generate-bill`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerName: selectedCustomer,
          startDate,
          endDate,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setBill(data.bill);
        setError(null);
      } else if (response.status === 404) {
        alert("No Data found");
      }
    } catch (err) {
      setError(err.message || "An error occurred");
      setBill(null);
    }
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("Order Summary", 20, 10);
    doc.autoTable({
      head: [
        [
          "Invoice Number",
          "Customer Name",
          "Product ID",
          "Total(CTN)",
          "Order Date",
          "Total Amount",
        ],
      ],
      body: filteredOrder.map((order) => [
        order.invoiceNumber,
        bill.customerName,
        order.productId,
        order.sales,
        order.date,
        order.totalOrderPrice,
      ]),
    });
    doc.save(`bill_${bill.customerName}.pdf`);
  };

  const filteredOrder = bill && bill.orders ? bill.orders : [];

  console.log(filteredOrder);

  return (
    <div className="order-summary-wrapper">
      <nav className="navigation">
        <button onClick={() => navigate("/dashboard")}>Dashboard</button>
        <button onClick={() => navigate("/view-inventory")}>
          View Inventory
        </button>
        <button onClick={() => navigate("/punch-order")}>Punch In Order</button>
        <button onClick={() => navigate("/revoke-order")}>Revoke Order</button>
        <button onClick={() => navigate("/generate-bill")}>
          Generate Bill
        </button>
      </nav>

      <h2 className="order-summary-h2">Order Summary</h2>

      <div className="filter-container-billing">
        <select
          className="filter-select"
          onChange={handleCustomerChange}
          value={selectedCustomer}
        >
          <option value="">Select Customer</option>
          {customers.map((customer) => (
            <option key={customer.customerId} value={customer.customerName}>
              {customer.customerName}
            </option>
          ))}
        </select>

        <h3>Start Date</h3>
        <input
          className="start-date"
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
        />

        <h3>End Date</h3>
        <input
          className="end-date"
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
        />

        <button className="submit-button-generate" onClick={handleSearch}>
          Search
        </button>

        <button className="submit-button-export" onClick={exportToPDF}>
          Export as PDF
        </button>
      </div>

      <div>
        <table>
          <thead>
            <tr>
              <th>Invoice Number</th>
              <th>Customer Name</th>
              <th>Product ID</th>
              <th>Total(CTN)</th>
              <th>Order Date</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrder.length > 0 ? (
              filteredOrder.map((order, index) => {
                // const pricePerPair = parseFloat(order.pricePerPair);
                const totalOrderPrice = parseFloat(order.totalOrderPrice);

                return (
                  <tr key={index}>
                    <td>{order.invoiceNumber}</td>
                    <td>{bill.customerName}</td>
                    <td>{order.productId}</td>
                    <td>{order.sales}</td>
                    {/* <td>{new Date(order.date).toLocaleString()}</td> */}
                    <td>{new Date(order.date).toLocaleDateString()}</td>
                    <td>
                      {/* Safely handle totalOrderPrice, display default if invalid */}
                      {!isNaN(totalOrderPrice)
                        ? totalOrderPrice.toFixed(2)
                        : "0.00"}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6">No orders available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderSummary;
