import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AddCustomer.css";
import axios from "axios";
import getApiUrl from "../utils.js";

const AddCustomer = () => {
  const [customerName, setCustomerName] = useState("");
  // const [customerEmail, setCustomerEmail] = useState("");
  // const [customerPhone, setCustomerPhone] = useState("");
  const navigate = useNavigate();
  const apiUrl = getApiUrl();

  const handleCustomerName = (e) => setCustomerName(e.target.value);
  // const handleCustomerEmail = (e) => setCustomerEmail(e.target.value);
  // const handleCustomerPhone = (e) => setCustomerPhone(e.target.value);

  // const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);
  // const isValidPhone = (phone) => /^\d{10}$/.test(phone);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!customerName) {
      alert("All fields are required!");
      return;
    }
    // if (!isValidEmail(customerEmail)) {
    //   alert("Please enter a valid email.");
    //   return;
    // }
    // if (!isValidPhone(customerPhone)) {
    //   alert("Please enter a valid 10-digit phone number.");
    //   return;
    // }

    try {
      const response = await fetch(`${apiUrl}/add-customer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ customerName }),
      });

      if (response.status === 400) {
        alert("Customer already exsit");
        setCustomerName("");
        // setCustomerEmail("");
        // setCustomerPhone("");
      } else {
        alert("Customer added successfully");
        setCustomerName("");
        // setCustomerEmail("");
        // setCustomerPhone("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="add-customer-wrapper">
      <nav className="navigation">
        <button onClick={() => navigate("/dashboard")}>Dashboard</button>
        <button onClick={() => navigate("/view-inventory")}>
          View Inventory
        </button>
        <button onClick={() => navigate("/punch-order")}>Punch In Order</button>
        <button onClick={() => navigate("/revoke-order")}>Revoke Order</button>
        <button onClick={() => navigate("/order-summary")}>
          Order Summary
        </button>
      </nav>
      <h2>Add Customer Details</h2>
      <form onSubmit={handleSubmit}>
        <div className="container-addCustomer">
          <h3>Customer Name:</h3>
          <input
            type="text"
            value={customerName}
            onChange={handleCustomerName}
            required
          />
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddCustomer;
