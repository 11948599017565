import React, { useEffect, useState } from "react";
import "./GenerateBill.css";
import { useNavigate } from "react-router-dom";
import getApiUrl from "../utils.js";
import jsPDF from "jspdf";
import "jspdf-autotable";

const GenerateBill = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [bill, setBill] = useState(null);
  const [error, setError] = useState(null);
  const [count, setCount] = useState(0);
  const [orderInvoice, setOrderInvoice] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState("");
  const navigate = useNavigate();
  const apiUrl = getApiUrl();

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const customerRes = await fetch(`${apiUrl}/get-customer`);
        const customerData = await customerRes.json();
        setCustomers(customerData || []);
      } catch (err) {
        console.error("Error fetching customers:", err);
      }
    };
    fetchCustomers();
  }, [apiUrl]);

  useEffect(() => {
    const fetchInvoiceNumbers = async () => {
      try {
        const res = await fetch(`${apiUrl}/get-history`);
        const data = await res.json();
        setOrderInvoice(data);
      } catch (err) {
        console.error("Error fetching invoices:", err);
      }
    };
    fetchInvoiceNumbers();
  }, []);

  useEffect(() => {
    if (selectedCustomer) {
      const filtered = orderInvoice.filter(
        (invoice) => invoice.customerName === selectedCustomer
      );

      // Get unique invoice numbers
      const uniqueInvoices = Array.from(
        new Set(filtered.map((invoice) => invoice.invoiceNumber))
      ).map((invoiceNumber) => {
        return filtered.find(
          (invoice) => invoice.invoiceNumber === invoiceNumber
        );
      });

      setFilteredInvoices(uniqueInvoices);
    } else {
      setFilteredInvoices([]);
    }
  }, [selectedCustomer, orderInvoice]);

  const handleCustomerChange = (e) => {
    setSelectedCustomer(e.target.value);
    setSelectedInvoice("");
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleInvoiceChange = (e) => {
    setSelectedInvoice(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedCustomer || !startDate || !endDate) {
      alert("Please select a customer and date range!");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/generate-bill`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerName: selectedCustomer,
          startDate,
          endDate,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setBill(data.bill);
        // console.log("Bill", data.bill);
        console.log("generate bill =>", data.bill);
        setError(null);
      } else if (response.status === 404) {
        alert("No data found");
      }
    } catch (err) {
      setError(err.message || "An error occurred");
      setBill(null);
    }
  };

  const filteredOrders =
    bill && bill.orders
      ? selectedInvoice === "all"
        ? bill.orders
        : bill.orders.filter(
            (order) => Number(order.invoiceNumber) === Number(selectedInvoice)
          )
      : [];

  console.log("filterORder => ", filteredOrders);
  const exportToPDF = () => {
    if (!bill || !bill.orders) {
      alert("No bill data to export.");
      return;
    }

    // Filter for the selected invoice orders
    const filteredOrder =
      bill && bill.orders
        ? selectedInvoice === "all"
          ? bill.orders // Include all orders if "All Invoices" is selected
          : bill.orders.filter(
              (order) => Number(order.invoiceNumber) === Number(selectedInvoice)
            )
        : [];

    if (filteredOrder.length === 0) {
      alert("No orders available for the selected invoice.");
      return;
    }

    console.log("filteredOrder=>", filteredOrder);

    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text("Bill Details", 14, 20);
    doc.setFontSize(12);
    doc.text(`Customer Name: ${bill.customerName}`, 14, 30);
    doc.text(`Invoice Number: ${selectedInvoice}`, 14, 36);
    doc.text(`Total Amount: $${bill.totalAmount.toFixed(2)}`, 14, 42);
    doc.text(`Bill Date:${new Date().toLocaleDateString("en-US", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    })}` ,doc.internal.pageSize.width - 70, // Adjust this value to position it in the right corner
    30);

    const tableColumn = [
      "S.No.",
      "Product Class",
      "Product ID",
      "Stock(CTN)",
      "Price/Pair",
      "Pair/Unit",
      "Total Pair",
      "Total Amount",
    ];
    const tableRows = [];

    filteredOrder.forEach((order, index) => {
      const pricePerPair = parseFloat(order.pricePerPair);
      const totalOrderPrice = parseFloat(order.totalOrderPrice);
      const orderData = [
        index + 1,
        order.productId,
        order.productClass,
        order.sales,
        !isNaN(pricePerPair) ? pricePerPair.toFixed(2) : "0.00",
        order.pricePerUnit,
        order.totalPair,
        !isNaN(totalOrderPrice) ? totalOrderPrice.toFixed(2) : "0.00",
        new Date(order.date).toLocaleString(),
      ];
      tableRows.push(orderData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 60,
    });

    doc.save(`bill_${bill.customerName}.pdf`);
  };

  return (
    <div className="generate-bill-wrapper">
      <nav className="navigation">
        <button onClick={() => navigate("/dashboard")}>Dashboard</button>
        <button onClick={() => navigate("/view-inventory")}>
          View Inventory
        </button>
        <button onClick={() => navigate("/punch-order")}>Punch In Order</button>
        <button onClick={() => navigate("/revoke-order")}>Revoke Order</button>
        <button onClick={() => navigate("/order-summary")}>
          Order Summary
        </button>
      </nav>
      <h2 className="generate-bill-h1">Generate Bill</h2>
      <div className="filter-container-billing">
        <select
          className="filter-select"
          onChange={handleCustomerChange}
          value={selectedCustomer}
        >
          <option value="">Select Customer</option>
          {customers.map((customer) => (
            <option key={customer.customerId} value={customer.customerName}>
              {customer.customerName}
            </option>
          ))}
        </select>

        <h3>Start Date</h3>
        <input
          className="start-date"
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
        />

        <h3>End Date</h3>
        <input
          className="end-date"
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
        />

        {selectedCustomer && startDate && endDate && (
          <select
            className="filter-select"
            onChange={handleInvoiceChange}
            value={selectedInvoice}
          >
            <option value="all">All Invoices</option>
            {filteredInvoices.map((invoice) => (
              <option key={invoice.invoiceNumber} value={invoice.invoiceNumber}>
                {invoice.invoiceNumber}
              </option>
            ))}
          </select>
        )}
        <button className="submit-button-generate" onClick={handleSubmit}>
          Generate Bill
        </button>

        <button className="submit-button-export" onClick={exportToPDF}>
          Export as PDF
        </button>
      </div>

      {error && <p style={{ color: "red" }}>{error}</p>}

      {bill && (
        <div>
          {/* Bill Details Section */}
          <h3>Bill Details</h3>
          <table className="bill-details-table">
            <tbody>
              <tr>
                <td>
                  <strong>Customer Name:</strong>
                </td>
                <td>{bill.customerName}</td>
              </tr>
              <tr>
                <td>
                  <strong>Invoice Number:</strong>
                </td>
                <td>{selectedInvoice}</td>
              </tr>
              <tr>
                <td>
                  <strong>Total Amount:</strong>
                </td>
                <td>{bill.totalAmount.toFixed(2)}</td>
              </tr>
              <tr>
                <td>
                  <strong>Bill Date:</strong>
                </td>
                <td>
                  {new Date().toLocaleDateString("en-US", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  })}
                </td>
              </tr>
            </tbody>
          </table>

          {bill && (
            <table className="bill-table">
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Product Class</th>
                  <th>Product ID</th>
                  <th>Stock(CTN)</th>
                  <th>Price/Pair</th>
                  <th>Pair/Unit</th>
                  <th>Total Pair</th>
                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody>
                {filteredOrders.length > 0 ? (
                  filteredOrders.map((order, index) => {
                    const pricePerPair = parseFloat(order.pricePerPair);
                    const totalOrderPrice = parseFloat(order.totalOrderPrice);

                    return (
                      <tr key={order.productId}>
                        <td>{index + 1}</td>
                        <td>{order.productClass}</td>
                        <td>{order.productId}</td>
                        <td>{order.sales}</td>
                        <td>
                          {!isNaN(pricePerPair)
                            ? pricePerPair.toFixed(2)
                            : "0.00"}
                        </td>
                        <td>{order.pricePerUnit}</td>
                        <td>{order.totalPair}</td>
                        <td>
                          {/* Safely handle totalOrderPrice, display default if invalid */}
                          {!isNaN(totalOrderPrice)
                            ? totalOrderPrice.toFixed(2)
                            : "0.00"}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="8">No orders found for this selection.</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default GenerateBill;
