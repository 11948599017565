import React, { useEffect, useState } from "react";
import { useInventoryDispatch } from "../Context/InventoryContext";
import { useNavigate } from "react-router-dom";
import "./RevokeOrder.css";
import getApiUrl from "../utils.js";

const RevokeOrder = () => {
  const [history, setHistory] = useState([]);
  const dispatch = useInventoryDispatch();
  const [revokeOrder, setRevokeOrder] = useState("");
  const navigate = useNavigate();
  const apiUrl = getApiUrl();

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const res = await fetch(`${apiUrl}/get-history`);
        const data = await res.json();
        console.log("revoke get history => ", data);
        setHistory(data);
      } catch (error) {
        console.error("Error fetching order history:", error);
      }
    };

    fetchHistory();
  }, []);

  const handleRevoke = async (entry) => {
    try {
      const res = await fetch(`${apiUrl}/revoke-order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productId: entry.productId,
          date: entry.date,
          revokeOrder: entry.revokeOrder,
        }),
      });

      const data = await res.json();
      console.log("Revoke response:", data);

      if (res.status === 200) {
        dispatch({
          type: "REVOKE_ORDER",
          payload: {
            productId: entry.productId,
            date: entry.date,
            revokeOrder: entry.revokeOrder,
          },
        });

        // Update local state to reflect the revocation
        setHistory((prevHistory) =>
          prevHistory.map((order) =>
            order.productId === entry.productId && order.date === entry.date
              ? { ...order, revoked: true }
              : order
          )
        );
        setRevokeOrder("");
        // setHistory(data);
      } else {
        console.error(`Failed to revoke order: ${data.message}`);
      }
    } catch (error) {
      console.error("Error revoking order:", error);
    }
  };

  // const handleRevokeOrderChange = (e, id) => {
  //   const { value } = e.target;
  //   setHistory((prevHistory) =>
  //     prevHistory.map((order) =>
  //       order.productId === productId ? { ...order, revokeOrder: value } : order
  //     )
  //   );
  // };

  return (
    <div className="revoke-order-container">
      <h2>Revoke Orders</h2>

      <div className="revoke-order-buttons">
        <button onClick={() => navigate("/dashboard")}>
          Go Back to Dashboard
        </button>
        <button onClick={() => navigate("/view-inventory")}>
          View Inventory
        </button>
        <button onClick={() => navigate("/add-product")}>Add Products</button>
        <button onClick={() => navigate("/punch-order")}>Punch In Order</button>
      </div>

      <table>
        <thead>
          <tr>
            <th>Order Id</th>
            <th>Product ID</th>
            <th>Customer Name</th>
            {/* <th>Stock Before</th>
            <th>Stock After</th> */}
            <th>Stock</th>
            <th>Price</th>
            <th>Employee Id</th>
            <th>Date & Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {history.map((entry, index) => (
            <tr key={index} className={entry.revoked ? "revoked" : ""}>
              <td>{entry.orderID}</td>
              <td>{entry.productId}</td>
              <td>{entry.customerName}</td>
              {/* <td>{entry.before_stock}</td>
              <td>{entry.after_stock}</td> */}
              <td>{entry.sellInCtn}</td>
              <td>{entry.price}</td>
              <td>{entry.employee_id}</td>
              <td>{new Date(entry.date).toLocaleString()}</td>
              <td>
                {!entry.revoked && (
                  <button onClick={() => handleRevoke(entry)}>Revoke</button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RevokeOrder;
